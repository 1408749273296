import { Col, Layout, List, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { CopyrightLink } from 'src/shared/components/CopyrightLink/CopyrightLink';
import {Cross, Doclist, Headset, Home, PhoneIncoming} from 'src/shared/icons';
import { CustomSearch } from 'src/shared/components/CustomSearch/CustomSearch';
import { CustomSpinner } from 'src/shared/components/CustomSpinner/CustomSpinner';
import { DiscountBanner } from 'src/shared/components/DiscuontBanner/DiscuontBanner';
import { FooterComponent } from 'src/shared/components/Footer/Footer';
import { HeaderComponent } from 'src/shared/components/Header/Header';
import { ListComponent } from '../../shared/components/List/List';
import { ListItem, WidgetServiceRequestType } from 'src/shared/types';
import { MobileFooterComponent } from 'src/shared/components/MobileFooter/MobileFooter';
import { goToPage, setShowOnlyContent } from 'src/shared/redux/routing/routingSlice';
import {resetAppointmentData, setDoctorId, setServiceRequestType} from 'src/shared/redux/appointment/appointmentSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { useGetWidgetInfoQuery, useGetWhiteLabelInfoQuery, useLazySearchQuery } from 'src/shared/redux/api/apiSlice';
import AppSearch from './Search/Search';
import React, { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import Typography from 'antd/lib/typography/Typography';
import styles from './styles.module.scss';

const SpecialtiesChoice = React.lazy(() => import('./SpecialtiesChoice/SpecialtiesChoice'));
const DoctorChoice = React.lazy(() => import('./DoctorChoice/DoctorChoice'));
const DoctorsList = React.lazy(() => import('./DoctorsList/DoctorsList'));
const AboutDoctor = React.lazy(() => import('./AboutDoctor/AboutDoctor'));
const SignUp = React.lazy(() => import('./SignUp/SignUp'));
const AboutClinic = React.lazy(() => import('./AboutClinic/AboutClinic'));
const Addresses = React.lazy(() => import('./Addresses/Addresses'));
const CallBack = React.lazy(() => import('./CallBack/CallBack'));

export const AppointmentModule = ({ handleThemeChange }: { handleThemeChange: (val: string, doctorsBackgroundColor: string, doctorsBorderColor: string) => void }) => {
  const { data, isLoading } = useGetWidgetInfoQuery('');
  const { data: whiteLabelData, isLoading: whiteLabelDataLoading } = useGetWhiteLabelInfoQuery('');
  const [search, searchQuery] = useLazySearchQuery();
  const [searchInput, setSearchInput] = useState<string>();
  const page = useAppSelector((state) => state.router.present.page);
  const showOnlyContent = useAppSelector((state) => state.router.present.showOnlyContent);
  const isChildren = useAppSelector((state) => state.filter.present.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.present.childrenAge);
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<ReactNode>();
  const debouncedSearch = useDebounce(searchInput || '', 300);

  const menuItems: ListItem[] = useMemo(() => {
    let items = [];

    if (data?.availableServiceTypes.some((item) => item.item1 === WidgetServiceRequestType.OnSite)) {
      items.push({
        key: 'appointment',
        title: 'Записаться на прием в клинику',
        icon: <Cross className="IconFill" />,
      });
    }

    if (data?.availableServiceTypes.some((item) => item.item1 === WidgetServiceRequestType.Online)) {
      items.push({
        key: 'consulting',
        title: 'Записаться на онлайн-консультацию',
        icon: <Headset className="IconFill" />,
      });
    }

    if (data?.availableServiceTypes.some((item) => item.item1 === WidgetServiceRequestType.AtHome)) {
      items.push({
        key: 'home',
        title: 'Вызов врача и услуги на дому',
        icon: <Home className="IconFill" />,
      });
    }

    items.push({
      key: 'doctorsList',
      title: 'Посмотреть всех врачей',
      icon: <Doclist className="IconFill" />,
    });

    items.push({
      key: 'callBack',
      title: 'Заказать обратный звонок',
      icon: <PhoneIncoming />,
    });

    return items;
  }, [data]);

  const handleMenuClick = (item: ListItem) => {
    if (item.key === 'appointment') {
      dispatch(setServiceRequestType(WidgetServiceRequestType.OnSite));
      dispatch(goToPage('appointment'));
    }
    if (item.key === 'consulting') {
      dispatch(setServiceRequestType(WidgetServiceRequestType.Online));
      dispatch(goToPage('appointment'));
    }

    if (item.key === 'home') {
      dispatch(setServiceRequestType(WidgetServiceRequestType.AtHome));
      dispatch(goToPage('appointment'));
    }

    if (item.key === 'callBack') {
      dispatch(goToPage(item.key));
    }
    
    if (item.key === 'doctorsList') {
      dispatch(goToPage(item.key));
    }
  };

  const handleSearch = async (input: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(input.target.value);
  };

  useEffect(() => {
    dispatch(resetAppointmentData());
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.doctorId) {
      dispatch(setDoctorId(params.doctorId));
      dispatch(goToPage('aboutDoctor'));
    }
    if (params.doctors === "true") {
      dispatch(goToPage('doctorsList'));
      dispatch(setShowOnlyContent(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      search({ data: debouncedSearch, ...(isChildren ? { serveAge: childrenAge } : {}) });
    }
  }, [debouncedSearch, search, isChildren, childrenAge]);

  useEffect(() => {
    if (data) {
      handleThemeChange(data.backgroundColor, data.doctorsBackgroundColor, data.doctorsBorderColor);
    }
  }, [data, whiteLabelData, dispatch, handleThemeChange]);

  useEffect(() => {
    if (page === 'home') {
      setContent(
        <Col className={styles.MenuCol}>
          <CustomSearch
            handleSearch={handleSearch}
            placeholder="Специализация, филиал или врач"
            className={styles.SearchInput}
            value={searchInput}
          />
          {searchInput ? (
            <AppSearch data={searchQuery.currentData} isLoading={searchQuery.isLoading || searchQuery.isFetching} />
          ) : (
            <ListComponent
              data={menuItems}
              containerClass={styles.ListContainer}
              renderFunc={(item: ListItem) => (
                <List.Item onClick={() => handleMenuClick(item)} className={styles.ListItem}>
                  <Row>
                    {item.icon}
                    <Typography className={styles.OptionText}>{item.title}</Typography>
                  </Row>
                </List.Item>
              )}
            />
          )}
          <DiscountBanner />
          {!whiteLabelData?.isServiceEnabled && <CopyrightLink />}
        </Col>,
      );
    }

    if (page === 'appointment') {
      setContent(<SpecialtiesChoice />);
    }

    if (page === 'doctors') {
      setContent(<DoctorChoice />);
    }

    if (page === 'doctorsList') {
      setContent(<DoctorsList />);
    }

    if (page === 'aboutDoctor') {
      setContent(<AboutDoctor />);
    }

    if (page === 'signUp/authorization') {
      setContent(<SignUp currentStep="authorization" />);
    }

    if (page === 'signUp/choosePatient') {
      setContent(<SignUp currentStep="choosePatient" />);
    }

    if (page === 'signUp/patientData') {
      setContent(<SignUp currentStep="patientData" />);
    }

    if (page === 'signUp/registration') {
      setContent(<SignUp currentStep="registration" />);
    }

    if (page === 'signUp/finished') {
      setContent(<SignUp currentStep="finished" />);
    }

    if (page === 'aboutClinic') {
      setContent(<AboutClinic />);
    }

    if (page === 'addresses') {
      setContent(<Addresses />);
    }

    if (page === 'callBack') {
      setContent(<CallBack />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchQuery, searchInput, menuItems]);

  return (
    <Layout className={`${styles.Wrapper}${showOnlyContent ? ` ${styles.DoctorsWrapper}` : ''}`}>
      {isLoading || whiteLabelDataLoading ? (
        <CustomSpinner />
      ) : (
        <>
          {!showOnlyContent && <HeaderComponent page={page} />}

          <Content className={styles.Container}>{content}</Content>

          {!showOnlyContent && <MobileFooterComponent page={page} />}
          {!showOnlyContent && <FooterComponent page={page} />}
        </>
      )}
    </Layout>
  );
};
